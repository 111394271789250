<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-6 col-12">Virtual Number Set</div>
      <div class="
          col-lg-6 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
        <button type="button" class="btn custom-view-detail-btn" @click="backtovirtualnumber()">
          Back To Virtual Number
        </button>
      </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item w-50" role="presentation">
        <button class="nav-link active w-100" id="new-setting-tab" data-bs-toggle="tab" data-bs-target="#newvotersetting"
          type="button" role="tab" aria-controls="vehicle-details" aria-selected="true">
          New Settings
        </button>
      </li>
      <li class="nav-item w-50" role="presentation">
        <button class="nav-link w-100" id="existing-setting-tab" data-bs-toggle="tab" data-bs-target="#existingvotersetting"
          type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectexistvirtualurl()">
          Existing Settings
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="newvotersetting" role="tabpanel" aria-labelledby="new-setting-tab">
        <div class="custom-tab-outer">
          <div class="d-flex
          justify-content-end mb-3">
            <button type="button" class="btn virtual-setting-btn" @click="addVirtualSettingModalOpen()">
              Add New Settings
            </button>
          </div>
          <div class="custom-ultima-datatable" style="height: calc(100vh - 262px)">
            <DataTable :value="virtualNumberSettingList" :scrollable="true" scrollHeight="flex" :paginator="false"
              :lazy="true" :rowHover="true"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :loading="loading">
              <template v-if="!loading" #empty>No records found.</template>
              <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
              <Column field="day" header="Day" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                  <div class="label-subheading">{{ data.day }}</div>
                </template>
              </Column>
              <Column field="deliverytime" header="Delivery Timing" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                  <div class="w-100">
                    <div class="
                          d-flex
                          align-items-center
                          justify-content-between
                          divider-custom-list
                        " v-for="items in data.timeSet" :key="items">
                      <div class="label-subheading">
                        <span class="service-border-gray-bg">{{ items.cb4 }}</span> -
                        <span class="service-border-gray-bg">{{ items.cb5 }}</span>
                      </div>
                      <div class="label-subheading" v-if="items.cb8 == 1">
                        Working Hours
                      </div>
                      <div class="label-subheading" v-if="items.cb8 == 2">
                        Non Working Hours
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="employees" header="Employees" headerStyle="width: 45%" bodyStyle="width: 45%">
                <template #body="{ data }">
                  <div class="w-100">
                    <div class="
                          d-flex
                          align-items-center
                          justify-content-between
                          text-capitalize
                          divider-custom-list
                        " v-for="items1 in data.timeSet" :key="items1">
                      {{ items1.employees }}
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="Employee" header="Action" class="justify-content-center" headerStyle="width: 15%"
                bodyStyle="width: 15%">
                <template #body="{ data }">
                  <button type="button" title="Edit" class="btn custom-outline-view-btn me-2"
                    @click="editVirtualSettingModalOpen(data)">
                    <i class="pi pi-pencil call-btn-color"></i>
                  </button>
                  <button type="button" title="Copy" class="btn custom-outline-call-btn me-2"
                    @click="copySetModalOpen(data)">
                    <i class="pi pi-copy call-btn-color"></i>
                  </button>
                  <button type="button" title="Remove" class="btn custom-outline-delete-btn" @click="deleteTimeSet(data)">
                    <i class="pi pi-trash delete-btn-color"></i>
                  </button>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="existingvotersetting" role="tabpanel" aria-labelledby="existing-setting-tab">
        
      </div>
    </div>
    <div class="modal-mask" v-if="addnumbersetting">
      <div class="
          modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
          custom-modal-outer
        ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Number Setting</h5>
            <button type="button" class="btn-close" @click="addVirtualSettingModalClose()"></button>
          </div>
          <div class="modal-body modal-min-height-set">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                  <label class="form-label">Select Day To Apply These Setting<span class="text-danger">*</span></label>
                  <Multiselect v-model="daysetvalue" :options="daysetvaluelist" label="label" placeholder="Select Day"
                    class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" :object="true"
                    @select="checkWeekDayes(daysetvalue)" :disabled="edit_status" />
                  <div class="custom-error" v-if="v$.daysetvalue.$error">
                    {{ v$.daysetvalue.$errors[0].$message }}
                  </div>
                  <div class="custom-error" v-if="checkdaymessage">
                    {{ checkdaymessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2" v-if="virtualnumbersetCount">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="accordion" id="accordionVirtualNumber">
                  <div class="accordion-item mb-2" v-for="(numbersettingset, index) in addnumbersettingset" :key="index">
                    <h2 class="accordion-header" :id="'headingNumberSet' + index">
                      <button class="accordion-button custom-accordion-focus-out-btn" type="button"
                        data-bs-toggle="collapse" :data-bs-target="'#collapseVirtualNumber' + index" aria-expanded="false"
                        :aria-controls="'collapseVirtualNumber' + index" @click="getOpenIndex(index, numbersettingset)">
                        Set {{ index + 1 }} Of Call Delivery Settings
                      </button>
                    </h2>
                    <div :id="'collapseVirtualNumber' + index" class="accordion-collapse collapse"
                      :aria-labelledby="'headingNumberSet' + index" data-bs-parent="#accordionVirtualNumber">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-lg-3 col-md-3 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">Start Time<span class="text-danger">*</span></label>
  
                              <Multiselect v-model="numbersettingset.startsettime" :options="timeList" label="label"
                                placeholder="Start Time" class="multiselect-custom text-capitalize" :canClear="false"
                                :closeOnSelect="true" :object="true" @select="checkValidation()" disabled />
                              <div class="custom-error" v-if="numbersettingset.startsettime_err">
                                {{ numbersettingset.startsettime_err }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-3 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">End Time<span class="text-danger">*</span></label>
  
                              <Multiselect v-model="numbersettingset.endsettime" :options="timeList[index]" label="label"
                                placeholder="End Time" class="multiselect-custom text-capitalize" :canClear="false"
                                :closeOnSelect="true" :object="true" :disabled="numbersettingset.startsettime == null"
                                @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.endsettime_err">
                                {{ numbersettingset.endsettime_err }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">Define Hours<span class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.definehours" :options="definehourslist" label="label"
                                placeholder="Select Define Hours" class="multiselect-custom text-capitalize"
                                :canClear="false" :closeOnSelect="true" :object="true" @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.definehours_err">
                                {{ numbersettingset.definehours_err }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">Forwarding Type<span class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.forwardingtype" :options="forwardingtypelist"
                                label="label" placeholder="Select Forwarding Type"
                                class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                :object="true" @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.forwardingtype_err">
                                {{ numbersettingset.forwardingtype_err }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">Define
                                <span v-if="numbersettingset.forwardingtype &&
                                  numbersettingset.forwardingtype.value != 0 &&
                                  numbersettingset.definehours &&
                                  numbersettingset.definehours.value != 2
                                  ">Welcome </span>Greeting<span class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.definegreeting" :options="definegreetinglist"
                                label="label" placeholder="Select Define Greeting"
                                class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                :object="true" @select="
                                  handleGreetingType(
                                    numbersettingset.definegreeting.value
                                  )
                                  " />
  
                              <div class="custom-error" v-if="numbersettingset.definegreeting_err">
                                {{ numbersettingset.definegreeting_err }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label class="form-label"><span v-if="numbersettingset.forwardingtype &&
                                numbersettingset.forwardingtype.value != 0
                                ">Welcome</span>
                                Greeting<span class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.greetingtype" :options="greetingtypelist" label="label"
                                placeholder="Select Greeting Type" class="multiselect-custom text-capitalize"
                                :canClear="false" :closeOnSelect="true" :object="true" @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.greetingtype_err">
                                {{ numbersettingset.greetingtype_err }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12" v-if="numbersettingset.forwardingtype &&
                            numbersettingset.forwardingtype.value > 0 &&
                            numbersettingset.definehours &&
                            numbersettingset.definehours.value != 2
                            ">
                            <div class="custom-form-group">
                              <label class="form-label">Define Hold Greeting<span class="text-danger">*</span></label>
                              <div class="custom-group-radio-box-btn">
                                <Multiselect v-model="numbersettingset.defineholdgreeting" :options="defineholdgreetinglist"
                                  label="label" placeholder="Select Define Hold Greeting"
                                  class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                  :object="true" @select="
                                    handleHoldGreetingType(
                                      numbersettingset.defineholdgreeting.value
                                    )
                                    " />
                                <div class="custom-error" v-if="numbersettingset.defineholdgreeting_err">
                                  {{ numbersettingset.defineholdgreeting_err }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12" v-if="numbersettingset.forwardingtype &&
                            numbersettingset.forwardingtype.value > 0 &&
                            numbersettingset.definehours &&
                            numbersettingset.definehours.value != 2
                            ">
                            <div class="custom-form-group">
                              <label class="form-label">Hold Greeting<span class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.holdgreetingtype" :options="holdgreetingtypelist"
                                label="label" placeholder="Select Hold Greeting Type"
                                class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                :object="true" @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.holdgreetingtype_err">
                                {{ numbersettingset.holdgreetingtype_err }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12" v-if="numbersettingset.forwardingtype &&
                            numbersettingset.forwardingtype.value > 1
                            ">
                            <div class="custom-form-group">
                              <label class="form-label">Forwarding Time Delay (In Seconds)<span
                                  class="text-danger">*</span></label>
                              <Multiselect v-model="numbersettingset.forwardingtime" :options="forwardingtimelist"
                                label="label" placeholder="Select Forwarding Time"
                                class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                :object="true" @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.forwardingtime_err">
                                {{ numbersettingset.forwardingtime_err }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group mb-0">
                              <label class="form-label d-flex justify-content-between"><span>Customer Care Employees<span
                                    class="text-danger">*</span></span><span class="small ms-1 text-muted">(Max
                                  4)</span></label>
                              <Multiselect v-model="numbersettingset.customerCareEmployees"
                                :options="customerCareEmployeesObj" :searchable="true" label="label"
                                placeholder="Select Customer Care Employees" class="multiselect-custom text-capitalize"
                                :canClear="true" :closeOnSelect="true" :object="true" mode="tags" :max="4"
                                @select="checkValidation()" />
                              <div class="custom-error" v-if="numbersettingset.customerCareEmployees_err">
                                {{ numbersettingset.customerCareEmployees_err }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button type="button" class="btn custom-view-detail-btn" @click="addMoreSetVirtualNumbers"
                  :disabled="checkdays">
                  Add More Set
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-end" v-if="!edit_status">
              <button type="button" class="btn setting-save-btn" @click="addNumberSettingDetail"
                :disabled="save_btn_status">
                <span v-if="!showloader">Save</span>
                <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader"></div>
              </button>
            </div>
            <div class="col-12 text-end" v-if="edit_status">
              <button type="button" class="btn setting-save-btn" @click="updateNumberSettingDetail"
                :disabled="save_btn_status">
                <span v-if="!showloader">Update</span>
                <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-mask" v-if="addnumbersettingcopy">
      <div class="
          modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered
          custom-modal-outer
        ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Copy Set</h5>
            <button type="button" class="btn-close" @click="copySetModalClose()"></button>
          </div>
          <div class="modal-body modal-min-height-set">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label">Copy Set<span class="text-danger">*</span></label>
                  <Multiselect v-model="otherdays" :options="otherDaysList" label="label" placeholder="Select Day"
                    class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" :object="true" />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <table class="
                    table
                    align-middle
                    table-responsive
                    custom-virtual-set-table
                  ">
                  <thead>
                    <tr class="custom-header-outer">
                      <th scope="col" style="width: 5%" class="text-center">#</th>
                      <th scope="col" style="width: 32%">Delivery Timing</th>
                      <th scope="col" style="width: 63%" class="text-center">
                        Employees
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(items, index) in copySetData.timeSet" :key="index">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <div class="label-subheading">
                          <span class="service-border-gray-bg">{{
                            items.cb4
                          }}</span>
                          -
                          <span class="service-border-gray-bg">{{
                            items.cb5
                          }}</span>
                        </div>
                      </td>
                      <td class="text-capitalize">{{ items.employees }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-end">
              <button type="button" class="btn setting-save-btn" @click="copyNumberSetOnOtherDay()"
                :disabled="otherdays == ''">
                <span v-if="!showloader">Copy</span>
                <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- confirmation modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
      <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
        <div class="modal-content text-center">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="confirm-dialog-header">Confirmation</div>
              </div>
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                  </div>
                  <div class="flex-grow-1 ms-3 confirm-dialog-body">
                    Are you sure you want to proceed?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-end">
              <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn" @click="confirmModalClose()">
                No
              </button>
              <button type="button" class="btn confirm-modal-yes-btn" @click="confirmModalProcessBtn()"
                :disabled="showconfirmloaderbtn">
                <span v-if="!showconfirmloaderbtn">Yes</span>
  
                <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showconfirmloaderbtn">
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- confirmation modal end here -->
  </template>
  <script>
  import { required, helpers } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  import ApiService from "../../../service/ApiService";
  
  export default {
    data() {
      return {
        v$: useValidate(),
        addnumbersetting: false,
        addnumbersettingcopy: false,
        showloader: false,
        showconfirmloaderbtn: false,
        virtualNumberSettingList: null,
        totalRecords: 0,
        loading: false,
        daysetvalue: "",
        daysetvaluelist: [],
        startsettime: null,
        endsettime: null,
        definehours: "",
        definehourslist: [
          { label: "Working Hours", value: "1" },
          { label: "Non Working Hours", value: "2" },
        ],
        forwardingtype: "",
        forwardingtypelist: [
          { label: "Missed Call", value: "0" },
          { label: "Connect Call", value: "1" },
        ],
        definegreetinglist: [
          { label: "Default Tune", value: "1" },
          { label: "Custom Tune", value: "2" },
        ],
        definegreeting: "",
        defineholdgreetinglist: [
          { label: "Default Tune", value: "1" },
          { label: "Custom Tune", value: "2" },
        ],
        greetingtype: "",
        greetingtypelist: [],
        defineholdgreeting: "",
        holdgreetingtype: "",
        holdgreetingtypelist: [],
        customerCareEmployees: [],
        customerCareEmployeesObj: [],
        forwardingtime: "",
        forwardingtimelist: [
          { label: "30 Seconds", value: "30" },
          { label: "40 Seconds", value: "40" },
          { label: "50 Seconds", value: "50" },
        ],
        copyset: "",
        copysetlist: [],
        addnumbersettingset: [],
        virtualnumbersetCount: 0,
        showmodeset: true,
        clientUserList: [],
        ctTuneList: [],
        cn1: null,
        ca1: null,
        seting_set_index: 0,
        error_status: false,
        save_btn_status: true,
        edit_status: false,
        checkdays: false,
        checkdaymessage: "",
        otherDaysList: [],
        otherdays: "",
        copySetData: [],
        confirm_popup_status: false,
        deleteTimeSetData: "",
        commonctandctholdtunesdata: [],
        defaultctandctholdtunesdata: [],
        timeList: {
          0: [
            { value: 1, label: "00:00:00" },
            { value: 2, label: "00:30:00" },
            { value: 3, label: "01:00:00" },
            { value: 4, label: "01:30:00" },
            { value: 5, label: "02:00:00" },
            { value: 6, label: "02:30:00" },
            { value: 7, label: "03:00:00" },
            { value: 8, label: "03:30:00" },
            { value: 9, label: "04:00:00" },
            { value: 10, label: "04:30:00" },
            { value: 11, label: "05:00:00" },
            { value: 12, label: "05:30:00" },
            { value: 13, label: "06:00:00" },
            { value: 14, label: "06:30:00" },
            { value: 15, label: "07:00:00" },
            { value: 16, label: "07:30:00" },
            { value: 17, label: "08:00:00" },
            { value: 18, label: "08:30:00" },
            { value: 19, label: "09:00:00" },
            { value: 20, label: "09:30:00" },
            { value: 21, label: "10:00:00" },
            { value: 22, label: "10:30:00" },
            { value: 23, label: "11:00:00" },
            { value: 24, label: "11:30:00" },
            { value: 25, label: "12:00:00" },
            { value: 26, label: "12:30:00" },
            { value: 27, label: "13:00:00" },
            { value: 28, label: "13:30:00" },
            { value: 29, label: "14:00:00" },
            { value: 30, label: "14:30:00" },
            { value: 31, label: "15:00:00" },
            { value: 32, label: "15:30:00" },
            { value: 33, label: "16:00:00" },
            { value: 34, label: "16:30:00" },
            { value: 35, label: "17:00:00" },
            { value: 36, label: "17:30:00" },
            { value: 37, label: "18:00:00" },
            { value: 38, label: "18:30:00" },
            { value: 39, label: "19:00:00" },
            { value: 40, label: "19:30:00" },
            { value: 41, label: "20:00:00" },
            { value: 42, label: "20:30:00" },
            { value: 43, label: "21:00:00" },
            { value: 44, label: "21:30:00" },
            { value: 45, label: "22:00:00" },
            { value: 46, label: "22:30:00" },
            { value: 47, label: "23:00:00" },
            { value: 48, label: "23:30:00" },
            { value: 49, label: "24:00:00" },
          ],
        },
        exist: {
          definecallforwardtune: "",
          definecallwelcometune: "",
          callforwardtune: "",
          callholdtune: "",
        },
        showexitsetloader: false,
  
      };
    },
  
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
  
    mounted() {
      this.getCtSettingConfig({ settingid: atob(this.$route.params.param2) });
      this.getWeekDays();
      this.getclientuser();
      this.cn1 = atob(this.$route.params.param1);
      this.ca1 = atob(this.$route.params.param2);
      this.getMasterData();
    },
  
    validations() {
      return {
        daysetvalue: {
          required: helpers.withMessage("Please select day", required),
        },
        exist: {
          definecallforwardtune: {
            required: helpers.withMessage("Please select define call forward tune", required),
          },
          definecallwelcometune: {
            required: helpers.withMessage("Please select define call welcome tune", required),
          },
          callforwardtune: {
            required: helpers.withMessage("Please select call forward tune", required),
          },
          callholdtune: {
            required: helpers.withMessage("Please select call hold tune", required),
          },
        },
      };
    },
  
    methods: {
      getWeekDays() {
        this.ApiService.getWeekDays().then((data) => {
          if (data.status == 200) {
            this.daysetvaluelist = data;
            console.log("serve",data);
          }
        });
      },
  
      getActiveUsers() {
        this.ApiService.getActiveUsers().then((data) => {
          if (data.status == 200) {
            this.customerCareEmployeesObj = data.data;
            this.appointmentTakenEmployeesObj = data.data;
          }
        });
      },
  
      getCtTunes(e) {
        this.ApiService.getcttunes({ type: e }).then((data) => {
          if (data.status == 200) {
            this.greetingtypelist = data.data;
          }
        });
      },
  
      getHoldCtTunes(e) {
        this.ApiService.getctholdtunes({ type: e }).then((data) => {
          if (data.status == 200) {
            this.holdgreetingtypelist = data.data;
          }
        });
      },
  
      getMasterData() {
        this.ApiService.getcommonctandctholdtunes().then((data) => {
          if (data.status == 200) {
            this.commonctandctholdtunesdata = data.data;
          }
        });
  
        this.ApiService.getdefaultctandctholdtunes().then((data) => {
          if (data.status == 200) {
            this.defaultctandctholdtunesdata = data.data;
          }
        });
      },
  
      handleGreetingType(e) {
        this.getCtTunes(e);
        this.checkValidation();
      },
  
      handleHoldGreetingType(e) {
        this.getHoldCtTunes(e);
        this.checkValidation();
      },
  
      getCtSettingConfig(e) {
        // console.log("e",e);
        this.loading = true;
        this.ApiService.getCtSettingConfig(e).then((data) => {
          if (data.success == true) {
            this.virtualNumberSettingList = data.records;
            this.loading = false;
          } else {
            this.loading = false;
            this.virtualNumberSettingList = null;
          }
        });
      },
  
      addVirtualSettingModalOpen() {
        this.addnumbersetting = true;
      },
  
      addVirtualSettingModalClose() {
        this.addnumbersetting = false;
        this.virtualnumbersetCount = 0;
        this.addnumbersettingset = [];
        this.daysetvalue = "";
        this.edit_status = false;
        this.greetingtypelist = [];
        this.holdgreetingtypelist = [];
      },
  
      copySetModalOpen(e) {
        this.copySetData = e;
        this.ApiService.otherdays({
          dayid: e.cb3,
          settingid: e.cb2,
        }).then((data) => {
          if (data.success == true) {
            this.addnumbersettingcopy = true;
            this.otherDaysList = data.records;
          } else {
            this.addnumbersettingcopy = false;
          }
        });
      },
  
      copySetModalClose() {
        this.addnumbersettingcopy = false;
      },
  
      checkWeekDayes(e) {
        this.ApiService.checkweekdayes({
          dayId: e.value,
          setting_id: this.ca1,
        }).then((data) => {
          if (data.success == true) {
            this.checkdays = false;
            this.checkdaymessage = "";
          } else {
            this.checkdays = true;
            this.checkdaymessage = data.message;
          }
        });
      },
  
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault();
        }
      },
  
      backtovirtualnumber() {
        this.$router.push("/cloudtelephony/virtualnumbers");
      },
  
      addNumberSettingDetail() {
        this.v$.$validate();
        var setData = [];
        for (let index = 0; index < this.addnumbersettingset.length; index++) {
          const element = this.addnumbersettingset[index];
          setData.push({
            cb4: element.startsettime.label,
            cb5: element.endsettime.label,
            cb6:
              element.forwardingtype && element.forwardingtype
                ? element.forwardingtype.value
                : null,
            cb7:
              element.forwardingtime && element.forwardingtime.value
                ? element.forwardingtime.value
                : null,
            cb8:
              element.definehours && element.definehours.value
                ? element.definehours.value
                : null,
            cb9:
              element.definegreeting && element.definegreeting.value
                ? element.definegreeting.value
                : 0,
            cb10:
              element.greetingtype && element.greetingtype.value
                ? element.greetingtype.value
                : null,
            cb11:
              element.greetingtype && element.greetingtype.tune
                ? element.greetingtype.tune
                : null,
            cb12:
              element.defineholdgreeting && element.defineholdgreeting.value
                ? element.defineholdgreeting.value
                : 0,
            cb13:
              element.holdgreetingtype && element.holdgreetingtype.value
                ? element.holdgreetingtype.value
                : null,
            cb14:
              element.holdgreetingtype && element.holdgreetingtype.tune
                ? element.holdgreetingtype.tune
                : null,
            cb15: element.customerCareEmployees[0].value,
            cb16: element.customerCareEmployees[0].label,
            cb17: element.customerCareEmployees[0].mobile_number,
            cb18:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].value
                : null,
            cb19:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].label
                : "",
            cb20:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].mobile_number
                : null,
            cb21:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].value
                : null,
            cb22:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].label
                : "",
            cb23:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].mobile_number
                : null,
            cb24:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].value
                : null,
            cb25:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].label
                : "",
            cb26:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].mobile_number
                : null,
          });
        }
        var fields = {};
        fields["setting_id"] = this.ca1;
        fields["number_id"] = this.cn1;
        fields["day_id"] = this.daysetvalue.value;
        fields["tuneData"] = setData;
        if (!this.v$.$error) {
          this.showloader = true;
          this.ApiService.savetunesconfig(fields).then((data) => {
            if (data.success == true) {
              this.showloader = false;
              var successMsg = data.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.addVirtualSettingModalClose();
              this.getCtSettingConfig({
                settingid: atob(this.$route.params.param2),
              });
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
            } else {
              this.showloader = false;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          });
        }
      },
  
      editVirtualSettingModalOpen(e) {
        this.edit_status = true;
        this.addnumbersetting = true;
        this.daysetvalue = { value: e.day_id, label: e.day };
        for (let index = 0; index < e.timeSet.length; index++) {
          const element = e.timeSet[index];
          if (element.cb5 != "24:00:00") {
            this.save_btn_status = true;
          } else {
            this.save_btn_status = false;
          }
          let start_time = this.timeList[0].filter(function (item) {
            return item.label == element.cb4;
          });
          let end_time = this.timeList[0].filter(function (item) {
            return item.label == element.cb5;
          });
          let define_hours = this.definehourslist.filter(function (item) {
            return item.value == element.cb8;
          });
          let forward_time = this.forwardingtypelist.filter(function (item) {
            return item.value == element.cb6;
          });
          let define_greeting = this.definegreetinglist.filter(function (item) {
            return item.value == element.cb9;
          });
  
          let greeting_type = [];
          if (define_greeting.length > 0) {
            if (define_greeting[0].value == 1) {
              greeting_type = this.defaultctandctholdtunesdata.filter(function (
                item
              ) {
                return item.value == element.cb10;
              });
              //this.getCtTunes(define_greeting[0].value);
            } else if (define_greeting[0].value == 2) {
              greeting_type = this.commonctandctholdtunesdata.filter(function (
                item
              ) {
                return item.value == element.cb10;
              });
              //this.getCtTunes(define_greeting[0].value);
            }
          }
  
          let define_hold_greeting = this.defineholdgreetinglist.filter(function (
            item
          ) {
            return item.value == element.cb12;
          });
  
          let hold_greeting_type = [];
          if (define_hold_greeting.length > 0) {
            if (define_hold_greeting[0].value == 1) {
              hold_greeting_type = this.defaultctandctholdtunesdata.filter(
                function (item) {
                  return item.value == element.cb13;
                }
              );
              //this.getHoldCtTunes(define_hold_greeting[0].value);
            } else if (define_hold_greeting[0].value == 2) {
              hold_greeting_type = this.commonctandctholdtunesdata.filter(
                function (item) {
                  return item.value == element.cb13;
                }
              );
              //this.getHoldCtTunes(define_hold_greeting[0].value);
            }
          }
  
          let forwarding_time = this.forwardingtimelist.filter(function (item) {
            return item.value == element.cb7;
          });
  
  
          let arrConverted2 = element.customer_care_employees_ids.split(",");
          let customerCareEmployees = [];
          for (let index = 0; index < arrConverted2.length; index++) {
            let vals = this.customerCareEmployeesObj.filter(function (item) {
              return item.value == arrConverted2[index];
            });
            if (vals.length > 0) {
              customerCareEmployees = [...customerCareEmployees, vals[0]];
            }
          }
  
          this.addnumbersettingset.push({
            day_id: this.daysetvalue.value,
            setting_id: this.ca1,
            cb1: element.cb1,
            startsettime: {
              value: start_time && start_time[0] ? start_time[0].value : 1,
              label:
                start_time && start_time[0] ? start_time[0].label : "00:00:00",
            },
            endsettime: { value: end_time[0].value, label: end_time[0].label },
            definehours: {
              value: define_hours[0].value,
              label: define_hours[0].label,
            },
            forwardingtype: {
              value: forward_time[0].value,
              label: forward_time[0].label,
            },
            definegreeting: {
              value:
                define_greeting && define_greeting[0]
                  ? define_greeting[0].value
                  : null,
              label:
                define_greeting && define_greeting[0]
                  ? define_greeting[0].label
                  : "",
            },
            greetingtype: {
              value:
                greeting_type && greeting_type[0] ? greeting_type[0].value : null,
              label:
                greeting_type && greeting_type[0] ? greeting_type[0].label : "",
            },
            defineholdgreeting: {
              value:
                define_hold_greeting && define_hold_greeting[0]
                  ? define_hold_greeting[0].value
                  : null,
              label:
                define_hold_greeting && define_hold_greeting[0]
                  ? define_hold_greeting[0].label
                  : "",
            },
            holdgreetingtype: {
              value:
                hold_greeting_type && hold_greeting_type[0]
                  ? hold_greeting_type[0].value
                  : null,
              label:
                hold_greeting_type && hold_greeting_type[0]
                  ? hold_greeting_type[0].label
                  : "",
            },
            forwardingtime: {
              value:
                forwarding_time && forwarding_time[0]
                  ? forwarding_time[0].value
                  : null,
              label:
                forwarding_time && forwarding_time[0]
                  ? forwarding_time[0].label
                  : "",
            },
            customerCareEmployees: customerCareEmployees,
          });
  
          this.virtualnumbersetCount++;
        }
      },
  
      updateNumberSettingDetail() {
        this.v$.$validate();
        var setData = [];
        for (let index = 0; index < this.addnumbersettingset.length; index++) {
          const element = this.addnumbersettingset[index];
          setData.push({
            cb1: element.cb1,
            cb4: element.startsettime.label,
            cb5: element.endsettime.label,
            cb6:
              element.forwardingtype && element.forwardingtype
                ? element.forwardingtype.value
                : null,
            cb7:
              element.forwardingtime && element.forwardingtime.value
                ? element.forwardingtime.value
                : null,
            cb8:
              element.definehours && element.definehours.value
                ? element.definehours.value
                : null,
            cb9:
              element.definegreeting && element.definegreeting.value
                ? element.definegreeting.value
                : 0,
            cb10:
              element.greetingtype && element.greetingtype.value
                ? element.greetingtype.value
                : null,
            cb11:
              element.greetingtype && element.greetingtype.tune
                ? element.greetingtype.tune
                : null,
            cb12:
              element.defineholdgreeting && element.defineholdgreeting.value
                ? element.defineholdgreeting.value
                : 0,
            cb13:
              element.holdgreetingtype && element.holdgreetingtype.value
                ? element.holdgreetingtype.value
                : null,
            cb14:
              element.holdgreetingtype && element.holdgreetingtype.tune
                ? element.holdgreetingtype.tune
                : null,
            cb15: element.customerCareEmployees[0].value,
            cb16: element.customerCareEmployees[0].label,
            cb17: element.customerCareEmployees[0].mobile_number,
            cb18:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].value
                : null,
            cb19:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].label
                : "",
            cb20:
              element.customerCareEmployees && element.customerCareEmployees[1]
                ? element.customerCareEmployees[1].mobile_number
                : null,
            cb21:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].value
                : null,
            cb22:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].label
                : "",
            cb23:
              element.customerCareEmployees && element.customerCareEmployees[2]
                ? element.customerCareEmployees[2].mobile_number
                : null,
            cb24:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].value
                : null,
            cb25:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].label
                : "",
            cb26:
              element.customerCareEmployees && element.customerCareEmployees[3]
                ? element.customerCareEmployees[3].mobile_number
                : null,
          });
        }
        var fields = {};
        fields["setting_id"] = this.ca1;
        fields["number_id"] = this.cn1;
        fields["day_id"] = this.daysetvalue.value;
        fields["tuneData"] = setData;
        if (!this.v$.$error) {
          this.showloader = true;
          this.ApiService.updatetunesconfig(fields).then((data) => {
            if (data.success == true) {
              this.showloader = false;
              var successMsg = data.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.addVirtualSettingModalClose();
              this.getCtSettingConfig({
                settingid: atob(this.$route.params.param2),
              });
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
            } else {
              this.showloader = false;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          });
        }
      },
  
      getOpenIndex(index, data) {
        this.seting_set_index = index;
        if (data.startsettime && data.startsettime.label) {
          var time = data.startsettime.label;
          if (index > 0) {
            var start_time = this.timeList[0].filter((i) => i.label > time);
            this.timeList[index] = JSON.parse(JSON.stringify(start_time));
            let last_ind = index - 1;
            let last_end_time =
              index >= 0 &&
                this.addnumbersettingset.length > 0 &&
                this.addnumbersettingset[last_ind].endsettime
                ? this.addnumbersettingset[last_ind].endsettime
                : { value: 1, label: "00:00:00" };
            this.addnumbersettingset[index].startsettime = last_end_time;
          } else {
            var start_time1 = this.timeList[index].filter((i) => i.label > time);
            this.timeList[index] = JSON.parse(JSON.stringify(start_time1));
          }
        }
      },
  
      checkValidation() {
        for (let index = 0; index < this.addnumbersettingset.length; index++) {
          let element = this.addnumbersettingset[index];
          let org_state = [...this.addnumbersettingset];
          let org_element = { ...org_state[index] };
          if (!element.startsettime) {
            org_element.startsettime_err = "Please select start time";
          } else {
            org_element.startsettime_err = "";
          }
          if (!element.endsettime) {
            org_element.endsettime_err = "Please select end time";
          } else {
            org_element.endsettime_err = "";
            if (element.endsettime.label != "24:00:00") {
              this.save_btn_status = true;
            } else {
              this.save_btn_status = false;
            }
          }
          if (!element.definehours) {
            org_element.definehours_err = "Please select hours";
          } else {
            org_element.definehours_err = "";
          }
          if (!element.forwardingtype) {
            org_element.forwardingtype_err = "Please select forwarding type";
          } else {
            org_element.forwardingtype_err = "";
          }
          if (!element.definegreeting) {
            org_element.definegreeting_err = "Please select greeting";
          } else {
            org_element.definegreeting_err = "";
          }
          if (!element.greetingtype) {
            org_element.greetingtype_err = "Please select greeting type";
          } else {
            org_element.greetingtype_err = "";
          }
          if (
            !element.defineholdgreeting &&
            element.forwardingtype &&
            element.forwardingtype.value > 0 &&
            element.definehours &&
            element.definehours.value != 2
          ) {
            org_element.defineholdgreeting_err = "Please select hold greeting";
          } else {
            org_element.defineholdgreeting_err = "";
          }
          if (
            !element.holdgreetingtype &&
            element.forwardingtype &&
            element.forwardingtype.value > 0 &&
            element.definehours &&
            element.definehours.value != 2
          ) {
            org_element.holdgreetingtype_err = "Please select hold greeting type";
          } else {
            org_element.holdgreetingtype_err = "";
          }
          if (!element.customerCareEmployees) {
            org_element.customerCareEmployees_err =
              "Please select customer care employees";
          } else {
            org_element.customerCareEmployees_err = "";
          }
          if (
            !element.forwardingtime &&
            element.forwardingtype &&
            element.forwardingtype.value > 1
          ) {
            org_element.forwardingtime_err = "Please select forwarding time";
          } else {
            org_element.forwardingtime_err = "";
          }
          org_element.forwardingtime_err = "";
          org_state[index] = org_element;
          this.addnumbersettingset = org_state;
  
          if (
            org_state[0].startsettime_err != "" ||
            org_state[0].endsettime_err != "" ||
            org_state[0].definehours_err != "" ||
            org_state[0].forwardingtype_err != "" ||
            org_state[0].definegreeting_err != "" ||
            org_state[0].defineholdgreeting_err != "" ||
            org_state[0].greetingtype_err != "" ||
            org_state[0].holdgreetingtype_err != "" ||
            org_state[0].customerCareEmployees_err != "" ||
            org_state[0].appointmentTakenEmployees_err != "" ||
            org_state[0].forwardingtime_err != ""
          ) {
            this.error_status = true;
          } else {
            this.error_status = false;
          }
        }
      },
  
      addMoreSetVirtualNumbers() {
        this.v$.$validate();
        if (!this.v$.daysetvalue.$error) {
          let index =
            this.addnumbersettingset.length > 0
              ? this.addnumbersettingset.length - 1
              : 0;
          let last_index = index;
          let last_end_time =
            last_index >= 0 &&
              this.addnumbersettingset.length > 0 &&
              this.addnumbersettingset[last_index].endsettime
              ? this.addnumbersettingset[last_index].endsettime
              : { value: 1, label: "00:00:00" };
  
          if (
            this.addnumbersettingset.length > 0 &&
            this.addnumbersettingset[last_index].endsettime &&
            this.addnumbersettingset[last_index].endsettime.label != "24:00:00"
          ) {
            this.addnumbersettingset.push({
              day_id: this.daysetvalue.value,
              setting_id: this.ca1,
              startsettime: last_end_time,
              ctb1: 0,
            });
          } else if (this.addnumbersettingset.length == 0) {
            this.addnumbersettingset.push({
              day_id: this.daysetvalue.value,
              setting_id: this.ca1,
              startsettime: last_end_time,
              ctb1: 0,
            });
          }
  
          this.virtualnumbersetCount = this.addnumbersettingset.length;
        }
      },
  
      copyNumberSetOnOtherDay() {
        var fields = {};
        fields["settingid"] = this.ca1;
        fields["dayid"] = this.otherdays.value;
        fields["sets"] = this.copySetData.timeSet;
        if (!this.v$.$error) {
          this.showloader = true;
          this.ApiService.copysettonewday(fields).then((data) => {
            if (data.success == true) {
              this.showloader = false;
              var successMsg = data.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.addnumbersettingcopy = false;
              this.copySetData = [];
              this.otherdays = "";
              this.getCtSettingConfig({
                settingid: atob(this.$route.params.param2),
              });
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
            } else {
              this.showloader = false;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          });
        }
      },
  
      deleteTimeSet(e) {
        this.deleteTimeSetData = e;
        this.confirm_popup_status = true;
      },
  
      confirmModalClose() {
        this.deleteTimeSetData = "";
        this.confirm_popup_status = false;
      },
  
      confirmModalProcessBtn() {
        var fields = {};
        fields["cb2"] = this.deleteTimeSetData.cb2;
        fields["cb3"] = this.deleteTimeSetData.cb3;
        if (!this.v$.$error) {
          this.showconfirmloaderbtn = true;
          this.ApiService.deletesettingset({ data: fields }).then((data) => {
            if (data.success == true) {
              this.showconfirmloaderbtn = false;
              var successMsg = data.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.deleteTimeSetData = "";
              this.confirm_popup_status = false;
              this.getCtSettingConfig({
                settingid: atob(this.$route.params.param2),
              });
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
            } else {
              this.showconfirmloaderbtn = false;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          });
        }
      },
      redirectexistvirtualurl() {
        let routePath = "/cloudtelephony/virtualnumbers/existset";
        this.$router.push(`${routePath}/${btoa(this.cn1)}/${btoa(this.ca1)}`);
      },
      submitExistSettingBtn() {
        this.v$.exist.$validate();
        let fields = {};
          if (this.exist.definecallforwardtune) {
              fields["ak110"] = this.exist.definecallforwardtune.value;
              fields["ak111"] = this.exist.definecallforwardtune.label;
          }
          if (this.exist.definecallwelcometune) {
              fields["ak110"] = this.exist.definecallwelcometune.value;
              fields["ak111"] = this.exist.definecallwelcometune.label;
          }
          if (this.exist.definecallwelcometune) {
              fields["ak110"] = this.exist.definecallwelcometune.value;
              fields["ak111"] = this.exist.definecallwelcometune.label;
          }
          if (this.exist.callholdtune) {
              fields["ak110"] = this.exist.callholdtune.value;
              fields["ak111"] = this.exist.callholdtune.label;
          }
        if (!this.v$.exist.$error) {
          this.showexitsetloader = true;
          // console.log("success");
        } else {
          this.showexitsetloader = false;
          // console.log("failed");
        }
      }
    },
  };
  </script>
  <style scoped>
  .setting-save-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    padding: 6px 16px;
    font-family: "AcuminPro-Regular";
    min-width: 80px;
  }
  
  .setting-save-btn:focus {
    box-shadow: none;
  }
  
  .custom-accordion-focus-out-btn {
    user-select: none;
  }
  
  .custom-accordion-focus-out-btn:focus {
    box-shadow: none;
  }
  
  .divider-custom-list {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d4e4f3;
  }
  
  .divider-custom-list:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .approve-heading {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
  }
  
  .approve-save-btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    padding: 6px 20px;
    font-family: "AcuminPro-Regular";
    min-width: 100px;
  }
  
  .approve-save-btn:focus {
    box-shadow: none;
  }
  
  .custom-virtual-set-table thead th {
    border: 1px solid #e4e4e4;
    padding: 1rem 1rem;
    letter-spacing: 0.26px;
    color: #4a5463;
    background: #f2f4fb;
    transition: none;
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .custom-virtual-set-table tbody td {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0.26px;
    color: #4a5463;
    line-height: 13px;
    border: 1px solid #e4e4e4;
    border-width: 1px;
    padding: 1rem 1rem;
  }
  
  .custom-ultima-datatable .label-subheading {
    line-height: 1.5;
  }
  
  .virtual-setting-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 19px;
    font-family: "AcuminPro-Regular";
  }
  
  .virtual-setting-btn:focus {
    box-shadow: none;
  }
  .modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    width: 115px;
  }
  
  .modal-bulk-next-btn:focus {
    box-shadow: none;
  }
  .search-voter-section-outer {
    display: block;
      overflow-y: auto;
      overflow-x: hidden;
  }
  </style>